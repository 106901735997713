import { WarningIcon } from "components/icons/WarningIcon";
import { PromoLabel } from "components/promoLabel";
import { SoldoutBanner } from "components/soldoutBanner";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FormattedMessage, useIntl } from "react-intl";
import { EL_DUENDE_DOMAIN } from "../../../env";
import { content } from "../../../i18n/content";
import { BookingContext } from "../../../providers/BookingProvider";
import { TimeTile } from "../../dayEvent";
import { FlexContent } from "../../FlexContent/FlexContent";
import { Spacer } from "../../spacer";
import { BigHeadline, BigLabel, SmallHeadline } from "../../typography.styles";
import { ShowTitle } from "./Calendar.styles";
import {
  BookingElement,
  EventDetailsWrapper,
  EventPrice,
  LabelWrapper,
  MinimizedText,
  MinimizedTextMore,
  WarningMessage,
} from "./styles";

const sortOrder = [
  "show_and_dinner",
  "show_and_tapas",
  "show_and_drink",
  "show_and_drink_b",
];

const getLowestPrice = (events) => {
  return Math.min(
    ...events.map((event) => {
      return event.prices[0].price;
    }),
  );
};

export const EventSelectionTablao = ({
  calendar,
  activeDate,
  activeProduct,
}) => {
  const { setActiveProduct, setPriceOption, adultTickets, childTickets } =
    useContext(BookingContext);
  const { locale, formatMessage } = useIntl();
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [fullDescriptions, setFullDescriptions] = useState([]);

  const handleFullDescription = (eventType: string) => {
    if (fullDescriptions.includes(eventType)) {
      setFullDescriptions(
        fullDescriptions.filter((item) => item !== eventType),
      );
    } else {
      setFullDescriptions([...fullDescriptions, eventType]);
    }
  };

  console.log(calendar[activeDate], "dd");

  return (
    <>
      <BookingElement>
        <BigHeadline isSerifFont={true} style={{ textTransform: "capitalize" }}>
          {dayjs(activeDate).format("dddd DD MMMM YYYY ")}
        </BigHeadline>
        <Spacer mt={{ xs: 22, md: 30 }} />
        <FlexContent direction="column">
          {}
          {Object.keys(calendar[activeDate])
            .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
            .map((eventType) => {
              return (
                <>
                  <BookingElement>
                    <LabelWrapper>
                      {eventType === "show_and_dinner" && (
                        <PromoLabel>
                          <FormattedMessage
                            defaultMessage="Best experience!"
                            id="13n9jb"
                          />
                        </PromoLabel>
                      )}
                    </LabelWrapper>

                    <ShowTitle direction="column">
                      <FlexContent>
                        <SmallHeadline isUppercase>
                          {content[locale][eventType]}
                        </SmallHeadline>
                      </FlexContent>
                      <Spacer mt={{ xs: 8 }} />
                      <MinimizedText
                        isFull={fullDescriptions.includes(eventType)}
                        onClick={() => handleFullDescription(eventType)}
                        style={{ maxWidth: 650 }}
                      >
                        {content[locale].descriptions[eventType]}
                      </MinimizedText>
                      <MinimizedTextMore
                        onClick={() => handleFullDescription(eventType)}
                      >
                        {fullDescriptions.includes(eventType) ? (
                          <FormattedMessage defaultMessage="hide" id="O4umPu" />
                        ) : (
                          <FormattedMessage
                            defaultMessage="read more"
                            id="0lF7Yp"
                          />
                        )}
                      </MinimizedTextMore>
                    </ShowTitle>
                    <Spacer mt={{ xs: 12, md: 20 }} />
                    <EventDetailsWrapper fullWidth justify="space-between" wrap>
                      <FlexContent wrap>
                        {calendar[activeDate][eventType]
                          .sort((a, b) => {
                            return dayjs(a.date).diff(dayjs(b.date));
                          })
                          .map((event) => {
                            return (
                              <>
                                <TimeTile
                                  onClick={() =>
                                    event.hasSecondLocation
                                      ? setIsWarningVisible(true)
                                      : [
                                          setActiveProduct(event),
                                          setPriceOption(event.prices[0]),
                                          setIsWarningVisible(false),
                                        ]
                                  }
                                  isActive={
                                    activeProduct
                                      ? event.eventId === activeProduct.eventId
                                      : false
                                  }
                                  time={dayjs(event.date).format("HH:mm")}
                                  isPromo={event.prices[0].promo}
                                  hasWarning={event.hasSecondLocation}
                                />
                              </>
                            );
                          })}
                      </FlexContent>
                      {activeProduct &&
                      activeProduct.eventType === eventType ? (
                        <EventPrice
                          direction="column"
                          alignItems="flex-end"
                          isPromo={activeProduct.prices[0].promo}
                        >
                          <div style={{ fontSize: "12px" }}>
                            <FormattedMessage
                              defaultMessage="Price:"
                              id="7UHlfH"
                            />
                          </div>
                          <BigLabel fontWeight={700}>
                            {activeProduct.prices[0].price} euro
                          </BigLabel>
                        </EventPrice>
                      ) : (
                        <EventPrice
                          direction="column"
                          alignItems="flex-end"
                          isPromo={
                            activeProduct &&
                            activeProduct.eventType === eventType
                              ? activeProduct.prices[0].promo
                              : false
                          }
                        >
                          <div style={{ fontSize: "12px" }}>
                            <FormattedMessage
                              defaultMessage="Starts from:"
                              id="smf3BQ"
                            />
                          </div>
                          <BigLabel fontWeight={700}>
                            {getLowestPrice(calendar[activeDate][eventType])}{" "}
                            euro
                          </BigLabel>
                        </EventPrice>
                      )}
                    </EventDetailsWrapper>
                    {eventType === "show_and_drink" && isWarningVisible && (
                      <>
                        <Spacer mt={{ xs: 12, md: 20 }} />
                        <WarningMessage>
                          <WarningIcon />
                          <div>
                            {formatMessage({
                              defaultMessage:
                                "Sorry we are sold out at Tablao Cordobes but don't worry",
                              id: "wiyGVt",
                            })}
                            {", "}
                            <strong>
                              {formatMessage({
                                defaultMessage:
                                  "we have tickets in our second location El Duende",
                                id: "XWvlBF",
                              })}
                            </strong>
                            {` (${formatMessage({
                              defaultMessage: "it is only 50m away",
                              id: "QfBx2D",
                            })}). ${formatMessage({
                              defaultMessage: "Click",
                              id: "X6qJ+M",
                            })} `}
                            <a
                              href={`https://${EL_DUENDE_DOMAIN}?date=${activeDate}&adults=${adultTickets}&child=${childTickets}`}
                              target="_blank"
                            >
                              <FormattedMessage
                                defaultMessage="HERE"
                                id="m3llsN"
                              />
                            </a>{" "}
                            <FormattedMessage
                              defaultMessage="to continue booking at El Duende"
                              id="D1NpdR"
                            />
                          </div>
                        </WarningMessage>
                      </>
                    )}
                  </BookingElement>
                  <Spacer mt={{ xs: 16 }} />
                </>
              );
            })}
        </FlexContent>
      </BookingElement>
      <Spacer mt={{ xs: 12, md: 24 }} />
      <SoldoutBanner isDuende={false} activeDate={activeDate} />
    </>
  );
};
