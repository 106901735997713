import React, { createContext, useContext, useEffect, useState } from "react";

// taken from clarity npm package, but it's a declare const
export interface ClarityInstance {
  init: (projectId: string) => void;
  setTag: (key: string, value: string | string[]) => void;
  identify: (
    customeId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string,
  ) => void;
  consent: (consent?: boolean) => void;
  upgrade: (reason: string) => void;
  event: (eventName: string) => void;
}

interface ClarityContextType {
  isInitialized: boolean;
  updateConsent: (hasConsent: boolean) => Promise<void>;
  setTag: (key: string, value: string) => void;
  identify: (
    customerId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string,
  ) => void;
  trackClarityEvent: (eventName: string) => void;
}

const ClarityContext = createContext<ClarityContextType | undefined>(undefined);

export const useClarity = () => {
  const context = useContext(ClarityContext);
  if (context === undefined) {
    throw new Error("useClarity must be used within a Clarity Provider");
  }

  return context;
};

interface ClarityProviderProps {
  children: React.ReactNode;
  projectId: string;
}

export const ClarityProvider: React.FC<ClarityProviderProps> = ({
  children,
  projectId,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [clarityModule, setClarityModule] = useState<ClarityInstance | null>(
    null,
  );

  useEffect(() => {
    const initializeClarity = async () => {
      try {
        // client-side only
        if (typeof window === "undefined") return;

        const ClarityModule = await import("@microsoft/clarity");
        ClarityModule.default.init(projectId);

        setClarityModule(ClarityModule.default);
        setIsInitialized(true);
      } catch (error) {
        console.error("Error setting Clatity tag:", error);
      }
    };
    initializeClarity();
  }, [projectId]);

  const updateConsent = async (hasConsent: boolean) => {
    if (!clarityModule) return;

    try {
      clarityModule.consent(hasConsent);
    } catch (error) {
      console.error("Error setting clarity tag:", error);
    }
  };

  const setTag = (key: string, value: string) => {
    if (!clarityModule) return;

    try {
      clarityModule.setTag(key, value);
    } catch (error) {
      console.error("Error setting Clarity tag:", error);
    }
  };

  const identify = (
    customerId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string,
  ) => {
    if (!clarityModule) return;

    try {
      clarityModule.identify(
        customerId,
        customSessionId,
        customPageId,
        friendlyName,
      );
    } catch (error) {
      console.error("Error identifying user in Clarity:", error);
    }
  };

  const trackClarityEvent = (eventName: string) => {
    if (!clarityModule) return;

    try {
      clarityModule.event(eventName);
    } catch (error) {
      console.error("Error tracking event in Clarity:", error);
    }
  };

  const value: ClarityContextType = {
    isInitialized,
    updateConsent,
    setTag,
    identify,
    trackClarityEvent,
  };

  return (
    <ClarityContext.Provider value={value}>{children}</ClarityContext.Provider>
  );
};
