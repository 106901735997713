import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "./App";
import {
  EL_DUENDE_DOMAIN,
  GTM_DUENDE_AUTH,
  GTM_DUENDE_PREVIEW,
  GTM_ID_DUENDE,
  GTM_ID_TABLAO,
  PUBLIC_POSTHOG_HOST,
  PUBLIC_POSTHOG_KEY,
} from "./env";
import "./global.css";
import { MicrosoftUETProvider } from "./providers/MicrosoftUET/MicrosoftUETContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

let tagManagerArgs;

if (window.location.hostname === EL_DUENDE_DOMAIN) {
  tagManagerArgs = {
    gtmId: GTM_ID_DUENDE,
    auth: GTM_DUENDE_AUTH,
    preview: GTM_DUENDE_PREVIEW,
  };
} else {
  tagManagerArgs = {
    gtmId: GTM_ID_TABLAO,
  };
}

TagManager.initialize(tagManagerArgs);

const options = {
  api_host: PUBLIC_POSTHOG_HOST,
};

const microsoftTiCode =
  window.location.hostname === EL_DUENDE_DOMAIN ? "97162158" : "343169078";

root.render(
  <React.StrictMode>
    <MicrosoftUETProvider
      config={{
        ti: microsoftTiCode,
        enableAutoSpaTracking: true,
      }}
    >
      <PostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={options}>
        <App />
      </PostHogProvider>
    </MicrosoftUETProvider>
  </React.StrictMode>,
);
