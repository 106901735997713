import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import {
  MicrosoftUETContextType,
  MicrosoftUETEvent,
  MicrosoftUETProviderProps,
} from "./types";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uetq: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UET: any;
  }
}

const MicrosoftUETContext = createContext<MicrosoftUETContextType | undefined>(
  undefined,
);

export const MicrosoftUETProvider: React.FC<MicrosoftUETProviderProps> = ({
  children,
  config,
}) => {
  const trackEvent = useCallback((event: MicrosoftUETEvent) => {
    if (!window.uetq) return;

    // Wrapped in Promise to move out of React's call stack, this is to test in development mode, as a cors error is sent without it
    Promise.resolve().then(() => {
      window.uetq.push("event", event.eventName, {
        ecomm_prodid: event.ecommProdid,
        ecomm_pagetype: event.ecommPagetype,
        revenue_value: event.revenueValue,
        currency: event.currency || "EUR",
        items: event.items,
      });
    });
  }, []);

  // Wrapped in Promise to move out of React's call stack, this is to test in development mode, as a cors error is sent without it
  const setCustomerData = useCallback((email: string, phone?: string) => {
    if (!window.uetq) return;

    Promise.resolve().then(() => {
      window.uetq.push("set", {
        pid: {
          em: email || "",
          ph: phone || "",
        },
      });
    });
  }, []);

  useEffect(() => {
    (function (w, d, t, r, u) {
      let f, n, i;
      (w[u] = w[u] || []),
        (f = function () {
          const o = config;
          // @ts-expect-error - Microsoft UET internal configuration
          (o.q = w[u]), (w[u] = new UET(o)), w[u].push("pageLoad");
        }),
        (n = d.createElement(t)),
        (n.src = r),
        (n.async = 1),
        (n.onload = n.onreadystatechange =
          function () {
            const s = this.readyState;
            (s && s !== "loaded" && s !== "complete") ||
              (f(), (n.onload = n.onreadystatechange = null));
          }),
        (i = d.getElementsByTagName(t)[0]),
        i.parentNode.insertBefore(n, i);
    })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
  }, []);

  return (
    <MicrosoftUETContext.Provider value={{ trackEvent, setCustomerData }}>
      {children}
    </MicrosoftUETContext.Provider>
  );
};

export const useMicrosoftUET = () => {
  const context = useContext(MicrosoftUETContext);
  if (context === undefined) {
    throw new Error(
      "useMicrosoftUET must be used within a MicrosoftUETProvider",
    );
  }
  return context;
};
