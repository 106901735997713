import { ReactElement, useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "../redux/store";
import { GlobalStyle } from "../styles/global";
import { Theme } from "../theme";
import BookingProvider from "./BookingProvider";
import { ClarityInstance, ClarityProvider } from "./Clarity/ClarityProvider";
import LangProvider from "./LangProvider";

const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

const handleCookieUpdate =
  (clarityRef: React.MutableRefObject<ClarityInstance | null>) =>
  (eventData: CustomEvent) => {
    const data = eventData.detail;
    if (clarityRef.current) {
      clarityRef.current.consent(data.accepted.includes("analytics"));
    }
  };

export const AppProviders = ({ children }: { children: ReactElement }) => {
  const clarityRef = useRef<ClarityInstance | null>(null);

  useEffect(() => {
    import("@microsoft/clarity").then((Clarity) => {
      Clarity.default.init(clarityProjectId);
      clarityRef.current = Clarity.default;
    });

    const listener: EventListener = handleCookieUpdate(
      clarityRef,
    ) as EventListener;
    document.addEventListener("cookieyes_consent_update", listener);
    return () => {
      document.removeEventListener("cookieyes_consent_update", listener);
    };
  }, []);

  return (
    <Router>
      <LangProvider>
        <ClarityProvider projectId={clarityProjectId}>
          <Provider store={store}>
            <BookingProvider>
              <Theme>
                {children}
                <GlobalStyle />
              </Theme>
            </BookingProvider>
          </Provider>
        </ClarityProvider>
      </LangProvider>
    </Router>
  );
};
